<template>
  <div>
    <b-container class="min-vh-100 d-flex flex-column" style="background-color: var(--background-color);">
    <b-row>
      <b-col cols="1"/>
      <b-col>
        <h1 style="margin-top: 4.25rem;">Registrierung</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="1"/>
      <b-col cols="10">
        <h4 style="margin-top: 2rem; margin-bottom: 1rem;">Bitte melde dich an</h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="1"/>
        <b-col >
          <b-input style="margin-bottom: 0.5rem" type="text" placeholder="Vorname" v-model="preName"/>
        </b-col>
        <b-col >
          <b-input style="margin-bottom: 0.5rem" type="text" placeholder="Nachname" v-model="lastName"/>
        </b-col>
      <b-col cols="1"/>
    </b-row>
    <b-row>
      <b-col cols="1"/>
        <b-col >
          <b-input style="margin-bottom: 0.5rem" type="text" placeholder="E-Mail" v-model="email"/>
        </b-col>
      <b-col cols="1"/>
    </b-row>
    <b-row>
      <b-col cols="1"/>
        <b-col>
          <b-input style="margin-bottom: 0.5rem" type="password" placeholder="Passwort" v-model="password1"/>
        </b-col>
      <b-col cols="1"/>
    </b-row>
    <b-row>
      <b-col cols="1"/>
        <b-col>
          <b-input type="password" placeholder="Passwort wiederholen" v-model="password2"/>
        </b-col>
      <b-col cols="1"/>
    </b-row>
    <b-row>
      <b-col cols="1"/>
        <b-col>
          <b-button  variant="primary" style="width: 100%; margin-top: 2rem" :disabled="!valid" @click="signup">Registrieren</b-button>
        </b-col>
      <b-col cols="1"/>
    </b-row>
  </b-container>
  </div>
</template>

<script>
import sha512 from 'js-sha512';
export default {
  name: 'Start',
  data() {
    return {
      email: '',
      password1: '',
      password2: '',
      preName: '',
      lastName: '',
    };
  },
  methods: {
    async signup() {
      if (!this.valid) {
        this.toast('Fehler', 'Ungültige Eingabe', 'danger');
        return;
      }
      // authotizes user from the backend
      const hashStr = `${this.email}:${this.preName}:${this.lastName}`;
      const hash = await sha512(hashStr);
      this.axios.post('auth/signup', {
        email: this.email,
        password: this.password1,
        preName: this.preName,
        lastName: this.lastName,
        hash,
      })
        .then((res) => {
          if (res.status !== 200) return this.toast('Es ist ein Fehler aufgetreten', 'Ungültige Login-Daten', 'danger');
          localStorage.setItem('token', res.data.token);
          this.$store.state.loggedIn = true;
          this.$store.state.preName = res.data.preName;
          this.$store.state.lastName = res.data.lastName;
          this.$router.push("/");
        })
        .catch((/* err */) => {
          this.toast('Account vergeben', 'Bitte verwende eine andere E-Mail-Adresse', 'danger');
        });
    },
  },
  computed: {
    pwdValid() {
      return this.pwd.length > 6;
    },
    mailValid() {
      const regex = RegExp(/^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,8}$/);
      return regex.test(this.$store.state.mailTo);
    },
    valid() {
      return this.email && this.preName && this.lastName && this.password1 && this.password2 && this.password1 === this.password2
    }
  },
};
</script>

<style scoped>

</style>
